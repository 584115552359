import React, { useState, useEffect, useRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../../../../utils/globalState'
import { colors } from '../../../../themes/colors'
import Alert from '../../../common/Alert'
import styles from './styles.module.css'

// component function
export default function DropZone(props) {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [highlight, setHighlight] = useState()
	const [file, setFile] = useState()
	const [error, setError] = useState()
	const [alertTask, setAlertTask] = useState()
	const location = useLocation()
	const alertObjectRef = useRef(null)
	const fileInputRef = useRef()
	const previewRef = useRef()
	const analysesExtra = globalState.userData?.organization?.freeAnalyses ? globalState.userData.organization.freeAnalyses : 0
	const maxMb = globalState.userData?.organization?.subscriptionPlan?.config?.Max_Mb_per_film ? globalState.userData.organization.subscriptionPlan.config.Max_Mb_per_film : 1000

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// check if file object is added in location (comes from dashboard)
	useEffect(() => {
		location.state?.type && validateFile(location.state)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[location.state])

	// selected file thumbnail
	useEffect(() => {
		var videoNode = document.querySelector('video')
		if (file?.type?.indexOf('video/') > -1) {
			previewRef.current.style.backgroundImage = 'none'
			videoNode.src = URL.createObjectURL(file)
			videoNode.currentTime = 1 // start 1 sec in to avoid black start frame
		} else if (file?.type?.indexOf('image/') > -1) {
			previewRef.current.style.backgroundImage = 'url(' + URL.createObjectURL(file) + ')'
			videoNode.src = null
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[file])

	// open file select dialogue
	function openFileDialog() {
		if (globalState.userData?.organization?.subscriptionPlan?.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else {
			fileInputRef.current.click()
		}
	}

	// validate file when added
	function onFileAdded(e) {
		validateFile(e.target.files[0])
	}

	// handle dropped file
	function onDrop(e) {
		e.preventDefault()
		setHighlight(false)
		validateFile(e.dataTransfer.files[0])
	}

	// handle drag enter
	function onDragOver(e) {
		e.preventDefault()
		setHighlight(true)
	}

	// handle drag leave
	function onDragLeave() {
		setHighlight(false)
	}

	// validate file
	function validateFile(file) {
		if (!file) return
		if (globalState.userData?.organization?.subscriptionPlan?.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else {
			let err = null
			err = file.size > maxMb * 1000 * 1000 ? 'Sorry, the video size exceeds the ' + maxMb + ' MB per video allowed in your subscription' : err
			err = (file.type !== 'video/mp4' && file.type.indexOf('image/') !== 0) ? 'Sorry, flowsam only likes .mp4 video or image files' : err
			setError(err ? err : null)
			setFile(err ? null : file)
			props.fileAdded(err ? null : file)
		}
	}

	// show analyses spent alert
	function showTrialAlert() {
		alertObjectRef.current = { title:'Sorry, you have spent your free trial analysis', message:'Please upgrade to a flowsam subscription to get more analyses', cancelLabel:'Cancel', actionLabel:'Upgrade', type:'confirm'}
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // upgrade
				window.location.href='https://flowsam.ai/features-plans/'
			}
			setAlertTask() // remove confirm dialogue
		})
	}

	const dropZoneStyle = { backgroundColor: (highlight ? colors.dropZoneHighlight : file ? 'transparent' : colors.background1) }
	const statusStyle = { pointerEvents:'none', color: error && !highlight && colors.error }
	const statusText = highlight ? 'Drop it right here' : error ? error : file ? '' /*file.name*/ : (
		<>
			<div className={styles.icon} />
			<p className={styles.newCta}>Drop video or image here</p>
		</>
	)

	return (
		<>
			<div ref={previewRef} className={styles.preview}>
				<video className={styles.previewVideo} />
			</div>
			<div
				className={styles.uploadField}
				style={dropZoneStyle}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
	    		onDrop={onDrop}
	    		onClick={openFileDialog}>
				<input
					type="file"
					ref={fileInputRef}
					style={{display:'none'}}
					onChange={onFileAdded}
					accept="video/mp4,image/*"
				/>
				<h4 style={statusStyle}>{statusText}</h4>
			</div>
			{alert}
		</>
	)
}
