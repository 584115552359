import React, { useState, useEffect, useContext } from 'react'
import { APIContext } from '../../../utils/api'
import Switch from 'react-switch'
import AnimateHeight from 'react-animate-height'
import DropDown from '../../common/DropDown'
import { assetTypes } from '../../../utils/assetTypes'
import { industries } from '../../../utils/industries'
import { colors } from '../../../themes/colors'
import styles from './styles.module.css'

// component function
export default function AnalysisListItemDetails(props) {

	const { item, closeDetails, projects, brands, updateProjects, updateBrands, setAlertTask, alertObjectRef } = props
	item.brand = item.brand ? item.brand : { id:null, name:null }

	const context = useContext(APIContext)
	const [height, setHeight] = useState(0)
	const [projectOptions, setProjectOptions] = useState(projects)
	const [brandOptions, setBrandOptions] = useState(brands)
	const [title, setTitle] = useState(item.title)
	const [brand, setBrand] = useState({ value:null, label:item.brand.name })
	const [subBrand, setSubBrand] = useState('')
	const [project, setProject] = useState({ value:null, label:item.project.name })
	const [comments, setComments] = useState('')
	const [version, setVersion] = useState('')
	const [assetTypeId, setAssetTypeId] = useState(item.analysisType === 1 ? 1 : item.analysisType === 2 ? 6 : 7) // TMP - HARD-CODED ASSET TYPE MAPPING !!!!!!! TODO: remove
	const [subIndustryId, setSubIndustryId] = useState(item.industryCode)
	const [ownAnalysis, setOwnAnalysis] = useState(true)
	const [submitting, setSubmitting] = useState(false)

	const originalAssetTypeId = item.analysisType === 1 ? 1 : item.analysisType === 2 ? 6 : 7 // TMP placeholder for canSubmit match

	// en-/disable submit button
	const canSubmit = (
		title !== item.title ||
		(item.brand && brand.label !== item.brand.name) ||
		project.label !== item.project.name ||
		parseInt(subIndustryId) !== parseInt(item.industryCode) ||

		// TMP HARDCODED:
		subBrand !== '' ||
		comments !== '' ||
		version !== '' ||
		parseInt(assetTypeId) !== parseInt(originalAssetTypeId) ||
		ownAnalysis !== true

		// TODO: subbrand, comments, version, assetTypeId, ownAnalysis
	)

	// update projects and brands on change
	useEffect(() => {
		setProjectOptions(projects)
		setBrandOptions(brands)
	}, [projects, brands])

	// toggle analysis type handler
	function toggleAnalysisType() {
		setOwnAnalysis(!ownAnalysis)
	}

	// validate form data and submit if all ok
	function validateAndSubmit() {
		if (!title.trim()) {
			showAlert('Please enter a name for your analysis')
			closeDetails(true)
		} else if (!brand) {
			showAlert('Please select or create a brand for your analysis')
			closeDetails(true)
		} else if (!project) {
			showAlert('Please select or create a project for your analysis')
			closeDetails(true)
		} else if (!assetTypeId) {
			showAlert('Please select an asset type for your analysis')
			closeDetails(true)
		} else if (!subIndustryId) {
			showAlert('Please select an industry for your analysis')
			closeDetails(true)
		} else if (canSubmit) {
			submitDetails()
		}
	}

	// submit data
	function submitDetails() {
		setSubmitting(true)
		const videoUpdateRequest = {
			title: title,
			brand: { name: brand.label },
			subbrand: subBrand,
			project: { name: project.label },
			comments: comments,
			version: version,
			analysisType: assetTypeId !== 5 ? 1 : 2, // TMP - HARD-CODED !!!!!!! TODO: remove
			industryCode: subIndustryId,
			ownanalysis: ownAnalysis,
			objectSegmentFilter: item.objectSegmentFilter
		}
		// send details data
		const dontCalculate = subIndustryId === item.industryCode ? "?dont_calculate" : "" // in some cases we don’t need to recalculate // TODO: assetType, ownAnalysis
		context.io.socket.post('/api/v1/video/' + item.guid + dontCalculate, videoUpdateRequest, (data, res) => {
			if (res.statusCode === 200) {
				// refresh parent project- and/or brand list if new project and/or brand was created
				brandOptions.find(br => br.label === brand.label) === undefined && updateBrands()
				projectOptions.find(proj => proj.label === project.label) === undefined && updateProjects()
				// update item props
				item.title = title
				item.project.name = project.label
				item.brand.name = brand.label
				item.industryCode = subIndustryId

				// TODO: update other item props (subBrand, comments, version, assetType, ownAnalysis)

				// close
				setSubmitting(false)
				setHeight(0)
				setTimeout(closeDetails,(height !== 0 ? 400 : 0))
			} else {
				setSubmitting(false)
				// TODO: error handling
			}
		})
	}

	// reveal/hide extra options
	function toggleHeight() {
		setHeight(height !== 0 ? 0 : 'auto')
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// close details overlay
	function closeDetailsOverlay() {
		// confirm close or save if changes
		if (canSubmit && !submitting) {
			alertObjectRef.current = { type:'confirm', title:'Save changes?'}
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					validateAndSubmit()
				} else {
					// reset values
					setTitle(item.title)
					setProject({ value:null, label:item.project.name })
					setBrand({ value:null, label:item.brand.name })
					setSubIndustryId(item.industryCode)
					setOwnAnalysis(true) // HARDCODED!!! TODO: use item value

					// TODO: reset other values (subBrand comments, version, assetType)

					setHeight(0)
					setTimeout(closeDetails,(height !== 0 ? 400 : 0))
					
				}
				setAlertTask() // remove alert
			})
		} else {
			setHeight(0)
			setTimeout(closeDetails,(height !== 0 ? 400 : 0))
		}
	}

	// update asset type id
	function updateAssetTypeId(e) {
		setAssetTypeId(e.target.value)
	}

	// update sub industry id
	function updateSubIndustryId(e) {
		setSubIndustryId(e.target.value)
	}

	// analysis type toggle
	const analysisTypeToggle = (
		<div className={styles.switchContainer}>
			<div className={ownAnalysis ? styles.enabled : styles.disabled} onClick={toggleAnalysisType}>Own ad</div>
			<Switch
				onChange={toggleAnalysisType}
				checked={!ownAnalysis}
				offColor={colors.switchBackground1}
				offHandleColor={colors.switchKnobOn}
				onColor={colors.switchBackground1}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id={'analysis-type-switch-'+item.guid}
			/>
			<div className={ownAnalysis ? styles.disabled : styles.enabled} onClick={toggleAnalysisType}>Competitor ad</div>
		</div>
	)

	// asset types filtered on video/image type and aspect ratio
	const mediaRatio = item.streamInfo?.displayAspectRatio || 2
	const assetTypeOptions = assetTypes.filter(item.analysisType === 3 ? t => t.analysisType === 3 :  t => t.analysisType !== 3 && (t.minRatio === undefined  || mediaRatio > t.minRatio)).map(type => { // TODO: new analysistype
		return <option key={'assettype-'+type.id+'-'+item.guid} value={type.id}>{type.label}</option>
	})

	// industries grouped for select options
	const subIndustryOptions = industries.map(industry => {
		return <optgroup key={'industry-'+industry.id+'-'+item.guid} label={industry.label}>
			{industry.subindustries.map(sub => {
				return <option key={'subindustry-'+sub.id+'-'+item.guid} value={sub.id}>{sub.label}</option>
			})}
		</optgroup>
	})

	return (
		<>
			<div className={styles.container}>
				<div className={styles.closeButton} onClick={closeDetailsOverlay}>&times;</div>
				<div>
					<div className={styles.inputLabel}>Analysis name</div>
					<input name="title" value={title} onChange={(e) => setTitle(e.target.value)} className={styles.inputText} />
				</div>
				<div className={styles.multiColumn}>
					<div className={styles.distribute}>
						<div className={styles.inputLabel}>Brand</div>
						<DropDown
							value={brand}
							options={brandOptions}
							onChange={(label)=>setBrand({ value:null, label:label })}
							searchable={true} small={true}
							placeholder="Select/create"
						/>
					</div>
					<div className={styles.distribute}>
						<div className={styles.inputLabel}>Sub brand</div>
						<input name="subbrand" value={subBrand} onChange={(e) => setSubBrand(e.target.value)} className={styles.inputText} />
					</div>
				</div>
				<div>
					<div className={styles.inputLabel}>Project</div>
					<DropDown
						value={project}
						options={projectOptions}
						onChange={(label)=>setProject({ value:null, label:label })}
						searchable={true} small={true}
						placeholder="Select/create"
					/>
				</div>
				<div>
					<div className={styles.inputLabel}>Comments</div>
					<input name="comments" value={comments} onChange={(e) => setComments(e.target.value)} className={styles.inputText} />
				</div>
				<div className={styles.multiColumn}>
					<div className={styles.distribute}>
						<div className={styles.inputLabel}>Version</div>
						<input name="version" value={version} onChange={(e) => setVersion(e.target.value)} className={styles.inputText} />
					</div>
					<div className={styles.button + ' ' + styles.more} onClick={toggleHeight}>{height !== 0 ? 'Less...' : 'More...'}</div>
					<div className={styles.button + ' ' + styles.submit + (!canSubmit ? ' ' + styles.disabled : '')} onClick={validateAndSubmit}>SAVE</div>
				</div>
				<AnimateHeight duration={400} height={height} disableDisplayNone={true}>
					<div className={styles.moreContainer}>
						<div className={styles.moreDescription}>Changes to the details below will require a new calculation of the analysis. This may take a while.</div>
						<div>
							<div className={styles.inputLabel}>Asset type</div>
							<div className={styles.selectWrapper}>
								<select value={assetTypeId} className={styles.select} onChange={updateAssetTypeId}>
									{assetTypeOptions}
								</select>
							</div>
						</div>
						<div>
							<div className={styles.inputLabel}>Industry</div>
							<div className={styles.selectWrapper}>
								<select value={subIndustryId} className={styles.select} onChange={updateSubIndustryId}>
									{subIndustryOptions}
								</select>
							</div>
						</div>
					</div>
					{analysisTypeToggle}
				</AnimateHeight>
			</div>
		</>
	)
}
